<template>
  <page-header-wrapper>
    <a-card>
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="商户号">
                <a-input v-model="queryParams.no" placeholder="请输入商户号" allow-clear @pressEnter="handleQuery" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="商户名称">
                <a-input v-model="queryParams.name" placeholder="请输入商户名称" allow-clear @pressEnter="handleQuery" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="商户简称">
                <a-input v-model="queryParams.shortName" placeholder="请输入商户简称" allow-clear @pressEnter="handleQuery" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="商户状态">
                <a-select v-model="queryParams.status" placeholder="请选择状态" style="width: 120px" @change="onChangeSelect">
                  <a-select-option value="1">
                    开
                  </a-select-option>
                  <a-select-option value="0">
                    关
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-row :gutter="10" class="margin-bottom">
        <a-col :span="1.5">
          <a-button plain type="primary" icon="el-icon-user" @click="add">新建商户号</a-button>
        </a-col>
      </a-row>
      <a-table :columns="columns" :data-source="data" rowKey="id" :loading="loading" :pagination="false">
        <div slot="status" slot-scope="text, record">
          <a-switch @change="onChange($event, record)" :checked="text == 1 ? true : text == 0 ? false : false"
            checked-children="开" un-checked-children="关" default-checked />
        </div>
        <div slot="action" slot-scope="text, record">
          <a href="javascript:void(0)" @click="edit(record)" class="margin-right-sm">编辑</a>
          <a-popconfirm title="确定要删除该商户吗？" ok-text="确定" cancel-text="取消" @confirm="del(record)">
            <a href="javascript:void(0)" style="color:red;">删除</a>
          </a-popconfirm>
        </div>
      </a-table>
      <!-- 分页 -->
      <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParams.pageNum"
        :total="total" :page-size="queryParams.pageSize" :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange" @change="changeSize" />
    </a-card>
    <!-- 组件 -->
    <right-drawer ref="rightDrawer" comName='addMerchants' @updateList="updateList" :title="drawerTitle" :id="curId"
      width="720px"></right-drawer>
  </page-header-wrapper>
</template>

<script>
import * as merchants from '@/api/pay/merchants.js'
import rightDrawer from '@/components/public/rightDrawer.vue'
export default {
  name: "PayMerchant",
  components: {
    rightDrawer
  },
  data() {
    return {
      columns: [
        {
          title: '商户号',
          dataIndex: 'no',
          key: 'no',
        },
        {
          title: '商户名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '商户简称',
          dataIndex: 'shortName',
          key: 'shortName'
        },
        {
          title: '开启状态',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 150,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      loading: false,
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        no: '',
        name: '',
        shortName: '',
        status: undefined,
        remark: ''
      },
      drawerTitle: '',
      curId: '',  // 编辑的数据id
    }
  },
  created() {
    this.getList()
  },
  methods: {
    /**
     * 获取商户号列表
     */
    getList() {
      this.loading = true;
      merchants.list(this.queryParams).then(res => {
        if (res.success) {
          this.data = res.data.records
          this.total = res.data.total;
          this.loading = false;
        }
      })
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams.name = ""
      this.queryParams.no = ''
      this.queryParams.name = '',
      this.queryParams.shortName = ''
      this.queryParams.status = undefined
      this.queryParams.remark = ''
      this.handleQuery();
    },
    add() {
      this.curId = ''
      this.drawerTitle = '添加商户号'
      this.$refs.rightDrawer.show()
    },
    /**switch
     * 切换商户号状态 开启或者关闭
     */
     onChangeSelect(e) {
      // let status = 0
      // e === true ? status = 1 :
      // e === false ? status = 0 : ''
      // this.queryParams.status = status
    },
    /**switch
     * 切换商户号状态 开启或者关闭
     */
    onChange(e, info) {
      let index = this.data.findIndex(item => { return item.id == info.id })
      let status = 0, msg = ''
      if (e === true) {
        status = 1
        msg = '开启'
      } else if (e === false) {
        status = 0
        msg = '关闭'
      }
      merchants.editStatus({
        id: info.id,
        status: status
      }).then(response => {
        this.loading = false;
        if (response.success) {
          this.$set(this.data[index], 'status', status)
          this.$message.success(msg + '成功')
        } else {
          /**  如果改变状态失败，重置为原来的状态  */
          this.$set(this.data[index], 'status', info.status)
          this.$message.error(msg + '失败')
        }
      })
    },
    edit(info) {
      this.drawerTitle = '编辑商户号'
      this.curId = info.id
      this.$refs.rightDrawer.show()

      if (this.$refs.rightDrawer.$refs.addMer) {
        this.$refs.rightDrawer.$refs.addMer.getInfo(info.id)
      } else {
        let timer = setInterval(() => {
          if (this.$refs.rightDrawer.$refs.addMer) {
            this.$refs.rightDrawer.$refs.addMer.getInfo(info.id)
            clearInterval(timer)
          }
        }, 100)
      }
    },
    del(info) {
      merchants.del({ id: info.id }).then(res => {
        if (res.success) {
          let delIndex = this.data.findIndex(item => { return info.id == item.id })
          this.data.splice(delIndex, 1)
          this.$message.success('删除成功')
        } else {
          this.$message.error('删除失败')
        }
      })
    },
    onShowSizeChange(current, pageSize) {
      this.queryParams.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParams.pageNum = current
      this.queryParams.pageSize = pageSize
      this.getList()
    },
    /** 孙组件保存数据后，更新列表
     * 来自于孙子组件的通知
     */
    updateList() {
      this.getList()
    }
  }
}
</script>

<style scoped></style>